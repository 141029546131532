// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/var/www/nodejs/osaifu2/page/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-features-js": () => import("/var/www/nodejs/osaifu2/page/src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-guide-js": () => import("/var/www/nodejs/osaifu2/page/src/pages/guide.js" /* webpackChunkName: "component---src-pages-guide-js" */),
  "component---src-pages-index-js": () => import("/var/www/nodejs/osaifu2/page/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policy-js": () => import("/var/www/nodejs/osaifu2/page/src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-terms-js": () => import("/var/www/nodejs/osaifu2/page/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

