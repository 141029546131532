module.exports = [{
      plugin: require('/var/www/nodejs/osaifu2/page/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ふたりでつける家計簿アプリ","short_name":"おさいふ","start_url":"/","background_color":"#f8ab86","theme_color":"#f8ab86","display":"minimal-ui","icon":"src/images/asset/osaifu-icon.png"},
    },{
      plugin: require('/var/www/nodejs/osaifu2/page/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-154882770-1"},
    },{
      plugin: require('/var/www/nodejs/osaifu2/page/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
